@charset "utf-8";/* CSS Document */
*{margin: 0;padding: 0;}
html {font-size: 62.5%;height: 100%}
body {margin: 0 auto;font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;height: 100%;overflow-x: hidden;max-width: 1920px;}
img {border: none;}
ul li {list-style-type: none;}
ul, form, p, a, img, table, tr, td, li, dd, dt, dl, span {margin: 0;padding: 0;list-style: none;color: #333;}
a {text-decoration: none;color: #333;outline: none;transition: 0.3s;-moz-transition: 0.3s;-o-transition: 0.3s;-webkit-transition: 0.3s;}
h1 {margin: 0;padding: 0;font-weight: normal;color: #333;}
.clear {clear: both;}
.maln {margin-left: auto !important}
input, textarea {font-family:Arial, Helvetica, sans-serif;font-size: 14px;font-size: 1.4rem;color:#333;border: none;outline: none;}
.l{ float:left;}
.r{ float:right;}
.fix{*zoom:1; } .fix:after,.fix:before{display:block; content:"clear"; height:0; clear:both; overflow:hidden; visibility:hidden; }

::-webkit-input-placeholder {/* WebKit browsers */
 color:#999;}
:-o-placeholder {/* Mozilla Firefox 4 to 18 */
 color:#999;}
::-moz-placeholder {/* Mozilla Firefox 19+ */
 color:#999; opacity: 1;}
:-ms-input-placeholder {/* Internet Explorer 10+ */
 color:#999;}

.search ::-webkit-input-placeholder {/* WebKit browsers */
color:#999;}
.search :-o-placeholder {/* Mozilla Firefox 4 to 18 */
color:#999;}
.search ::-moz-placeholder {/* Mozilla Firefox 19+ */
color:#999; opacity: 1;}
.search :-ms-input-placeholder {/* Internet Explorer 10+ */
color:#999;}

.div3 ::-webkit-input-placeholder {/* WebKit browsers */
color:#333;}
.div3 :-o-placeholder {/* Mozilla Firefox 4 to 18 */
color:#333;}
.div3 ::-moz-placeholder {/* Mozilla Firefox 19+ */
color:#333; opacity: 1;}
.div3 :-ms-input-placeholder {/* Internet Explorer 10+ */
color:#333;}
    
 
.wp {width: 1400px;margin: auto;}
@media screen and (min-width:1420px) {.wp {width: 1400px;margin: auto;}}
@media screen and (min-width:1220px) and (max-width:1419px) {.wp {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp {width: 95%;margin: auto;}}

.wp2 {width: 1400px;margin: auto;}
@media screen and (min-width:1220px) {.wp2 {width: 1200px;margin: auto;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.wp2 {width: 980px;margin: auto;}}
@media screen and (max-width:999px) {.wp2 {width: 95%;margin: auto;}}

.no_margin{margin-left: auto\0!important;}
@media screen and (min-width:1220px) {.no_margin{margin-left: auto!important;}}
@media screen and (min-width:1000px) and (max-width:1219px) {.no_margin{margin-left: auto!important;}}

/*.container*/
.container { width: 5px; height: 100%; position: absolute; right: 0; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 999999999999999; cursor: pointer; position: fixed; top: -5px; right: 0; display: block; }

.container .nav { overflow: hidden; width: 300px; background: rgba(0, 0, 0, 0.8); position: absolute; top: 0; right: 0; display: block; height: auto; overflow: scroll; }

.container .nav form { margin: 15px auto; width: 258px; height: 26px; border: 1px solid #fff; position: relative;background: #fff;}

.container .nav form .iptc { box-sizing: border-box; padding-left: 15px; background-color: #fff; width: 80%; height: 26px; line-height: 26px; display: block; float: left; }

.container .nav form .ipsc { width: 20%; height: 26px; line-height: 26px; display: block; float: right; background: transparent; position: relative;z-index: 99;}

.container .nav form i{position: absolute;right: 10px;top: 5px;}

.container .nav > ul { display: none; margin-top: 0px; margin-bottom: 0px; overflow: scroll; padding-top:45px;-ms-box-sizing: border-box;-o-box-sizing: border-box;-webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}

.container .nav > ul > li { line-height: 40px; font-size: 12px; font-size: 1.2rem; text-indent: 20px; position: relative; }

.container .nav > ul > li .addition { width: 20px; height: 20px; position: absolute; right: 15px; top: 10px; background: url("fonts/shizi.png") no-repeat center center;  }

.container .nav > ul > li .addition.fa:before { margin-right: 5px; color: #fff; text-indent: 0; }

.container .nav > ul > li > a { color: #fff; font-size: 12px; font-size: 1.2rem; display: block; margin-right: 40px;}

.container .nav > ul > li > a span { color: #fff; font-size: 12px; font-size: 1.2rem; }

.container .nav > ul > li .c-show { display: none; width: 100%; }

.container .nav > ul > li .c-show1 .c-s1 { position: relative; padding-left: 15px;}

.container .nav > ul > li .c-show1 .c-s1 > a {position: relative;  font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2{position: relative; }

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a { text-indent: 35px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; margin-right: 40px;}

.container .nav > ul > li .c-show1 .c-s1 > .c-show2 .c-s2 > a  span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 {position: relative;}

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a { text-indent: 50px; font-size: 12px; font-size: 1.2rem; color: #fff; display: block; padding-top: 5px; padding-bottom: 5px; line-height: 30px; }

.container .nav > ul > li .c-show1 .c-s1 .c-s2 .c-show3 .c-s3 a span { font-size: 12px; font-size: 1.2rem; color: #fff; }

.container .nav > ul > dl{padding: 20px;}

.container .nav > ul > dl dd{float: left;margin: 0 2px;}

.container .menubtn { width: 23px; height: 18px; position: absolute; top: 12px; right: 12px; -webkit-transition: all 0.4s ease 0s; transition: all 0.4s ease 0s; z-index: 9999; }

.container .menubtn span { display: block; width: 100%; height: 3px; margin-bottom: 3px; background-color: #1f5d92; }

@media screen and (min-width: 768px) { .container, .nav { display: none!important; } }


/***********  lunbo  *************/
*{margin: 0;padding:0;}
li{list-style: none;}

.slide { position: relative;}

.slide .carouse { width: 100%; overflow: hidden; position: relative; }

.slide .carouse .slideItem { width: 100%; position: absolute; cursor: pointer; }

.slide .carouse .slideItem .banner-img { width: 100%;display: block; }

.slide .console { height: 57px; display: inline-block; width: 35px; position: absolute; top: 40%; }

.slide .dotList { position: absolute;bottom: 49px; text-align: center; left: 19%;}

.slide .dotList ul li { display: inline-block;cursor: pointer; vertical-align: middle;}

.slide .carousel-control { position: absolute; top: 50%; width: 60px; height: 60px; z-index: 999; margin-top: -30px;}

.slide .carousel-control.left { left: 8%; background-image: url("../images/prev.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.left:hover { background-image: url("../images/prev2.png"); }

.slide .carousel-control.right { right: 8%; background-image: url("../images/next.png"); background-repeat: no-repeat; cursor: pointer; }

.slide .carousel-control.right:hover { background-image: url("../images/next2.png"); }

.slide .dotList ul li{cursor: pointer; background:#fff;  font-size: 0px;   margin-left: 6px;margin-right: 6px; border: none;outline: none;width: 12px;height:12px;border-radius: 50%;}
.slide .dotList ul li.active{background:#ff7e0e;width: 20px;height: 20px;}

.slide .slidedetail { display: none; }

@media screen and (max-width:999px) {
    .slide .carousel-control{display: none;}
}
@media screen and (max-width:767px) {
    .slide .dotList{bottom: 10px;}
}

/***********  head  ************/
.head{position: absolute;left: 0;top: 0;width: 100%;z-index: 9999;}
.logo{padding-top: 45px;}
.logo img{max-width: 100%;}
.navlist{padding-top: 15px;}
.navlist2{display: none;}
.navlist>li{float: left;padding-left: 35px;position: relative;background: url(../images/navbg.png) no-repeat left 2px;margin-left: 32px;}
.navlist>li:first-child{padding-left: 0;background: none;margin-left: 0;}
.navlist>li>a{font-size: 16px;font-size: 1.6rem;display: block;padding-bottom: 3px;color: #1f5d92;border-bottom: 2px solid transparent;}
.navlist>li:hover>a{border-bottom: 2px solid #1f5d92;}
.navlist>li.currentnav>a{border-bottom: 2px solid #1f5d92;}

.headyou{padding-top: 21px;}
.topsjj a{margin-left: 5px;}
.topsjj a i{font-size: 30px;font-size: 3rem;color: #999999;}
.topsjj a i:hover{color: #1f5d92;}

.search{position: relative;margin-left: 10px;margin-right: 10px;}
.sou{width: 223px;height: 31px;line-height: 31px;padding-left: 7px;border: 1px solid #aaaaaa;background: transparent;color: #999999;}
.suo{position: absolute;right: 1px;top: 1px;width: 27px;height: 31px;background: transparent;cursor: pointer;}
.search form i{position: absolute;right: 1px;top: 1px;width: 27px;height: 31px;text-align: center;line-height: 31px;font-size:20px;font-size:2rem;color: #a9afb5;}

.m-hdlang { position: relative; overflow: visible; float: right;}

.m-hdlang .lang-list { position: absolute; left: 0; top: 100%; background-color: #FFF; z-index: 999; width: 100%; display: none; text-align: center; font-size: 12px; font-size: 1.2rem; border: solid 1px #C5C5C5; margin-top: -2px; padding-top: 3px; padding-bottom: 1px; }

.m-hdlang .lang-item { float: none; color: #666666; line-height: 24px; }

.m-hdlang .lang-item a { color: #555; }

.m-hdlang .lang-item a:hover { color: #30a569; }

.m-hdlang > .lang-item { font-size: 14px; font-size: 1.4rem; line-height: 30px;display: inline-block; width: 97px;text-align: center;border: 1px solid #aaaaaa;}
.m-hdlang > .lang-item i{font-size: 12px;font-size: 1.2rem;margin-left: 9px;}

@media screen and (max-width:1419px) {
    .navlist > li{padding-left: 25px;margin-left: 25px;}
}
@media screen and (max-width:1219px) {
    .logo{width: 300px;}
    .navlist > li{padding-left: 15px;margin-left: 15px;}
}
@media screen and (max-width:999px) {
    .navlist > li{margin-left: 17px;}
    .navlist > li > a{font-size:14px;font-size:1.4rem;}
    .logo{width: 250px;padding-top: 24px;}
    .head{background: #fff;padding-bottom: 10px;position: static;}
}
@media screen and (max-width:900px) {
    .sou{width: 140px;}
    .headyou{width: 100%;}
    .navlist{width: 100%;padding-top: 25px;}
    .head{text-align: center;}
    .logo{display: block;float: none;margin: 0 auto;}
    .m-hdlang{display: inline-block;float: none;}
    .search{display: inline-block;float: none;}
    .topsjj{display: inline-block;float: none;position: relative;top: 5px;}
    .navlist > li{width: 14.28%;padding: 0;margin: 0;background: none;}
    .navlist > li > a{font-size:16px;font-size:1.6rem;padding-bottom: 7px;}
}
@media screen and (max-width:767px) {
    .navlist1{display: none;}
}
@media screen and (max-width:550px) {
    .topsjj,.search{display: none;}
    .logo{width: 220px;}
    .headyou{padding-top: 12px;}
}

/*************  syp  **************/
.sypbox{width: 20%;float: left;}
.sypcon{border-top: 1px solid #fff;position: relative;overflow: hidden;border-left: 1px solid #fff;}
.syp .sypbox:first-child .sypcon{border-left: 1px solid #1e4d77;}
.syptu img{display: block;width: 100%;max-width: 383px;max-height: 384px;transition: 0.5s;}
.syptu:hover img{transform: scale(1.1);}
.sypcover{position: absolute;left: 0;bottom: -100%;width: 100%;background: url(../images/sypcover.png);padding-top: 16px;padding-bottom: 20px;transition: 0.5s;}
.sypp1{display: block;margin: 0 16px;color: #fff;font-size:16px;font-size:1.6rem;font-weight: bold;line-height: 34px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;}
.sypp2{display: block;margin: 0 16px;color: #fff;line-height: 24px;height: 48px;overflow: hidden;margin-bottom: 13px;}
.sypp3{display: block;margin: 0 16px;color: #fff;line-height: 24px;}
.sypcover2{position: absolute;left: 0;bottom: 0;width: 100%;background: url(../images/sypcover.png);padding-top: 9px;padding-bottom: 4px;}
.sypbox:hover .sypcover{bottom: 0;}
.sypbox:hover .sypcover2{display: none;}

@media screen and (max-width:1219px) {
    .sypp1{font-size:14px;font-size:1.4rem;line-height: 30px;}
    .sypcover{padding-top: 6px;padding-bottom: 10px;}
    .sypp2{margin-bottom: 6px;}
}
@media screen and (max-width:999px) {
    .sypcover2{display: none;}
    .sypcover{bottom: 0;}
}
@media screen and (max-width:900px) {
    .sypp1{margin: 0 6px;}
    .sypp2{margin: 0 6px;}
    .sypp3{margin: 0 6px;}
}
@media screen and (max-width:767px) {
    .sypbox{width: 33.333%;}
    .sypcon{border-left:1px solid #1e4d77;}
}
@media screen and (max-width:650px) {
    .sypbox{width: 50%;}
}
@media screen and (max-width:380px) {
    .sypbox{width: 100%;}
}

/***************  sya  ****************/
.sya{padding-top: 71px;padding-bottom: 140px;background: url(../images/sybg1.jpg) no-repeat center top;}
.syap1{display: block;color: #1f5d92;font-family: "HelveticaLTStd-BlkCond";text-align: center;font-size: 30px;font-size: 3rem;}
.syap2{display: block;color: #666666;line-height: 24px;margin-top: 25px;margin-bottom: 90px;text-align: center;}
.syap3{display: block;width: 195px;height: 44px;line-height: 44px;text-align: center;background: #1f5d92;color: #fff;font-size:18px;font-size:1.8rem;font-weight: bold;margin: 0 auto;}
.syap2:hover{color: #1f5d92;}
.syap3:hover{background: #333;}

@media screen and (max-width:1219px) {
    .syap2{margin-bottom: 40px;}
}
@media screen and (max-width:999px) {
    .sya{padding-top: 50px;}
    .syap1{font-size:24px;font-size:2.4rem;}
}
@media screen and (max-width:767px) {
    .sya{padding-top: 30px;}
    .syap2{margin-bottom: 30px;}
    .sya{background: #e4ecf8;padding-bottom: 40px;}
}

/**************  sygc  **************/
.sygc{background: url(../images/sybg4.jpg) no-repeat center top;}

.sybt{text-align: center;font-size: 50px;font-size: 5rem;text-transform: uppercase;font-family: "HelveticaLTStd-BlkCond";}
.sybt a{color: #1f5d92;}
.sygc .sybt{margin-bottom: 52px;}
.sygcbox{width: 32.29%;margin-left: 1.565%;float: left;position: relative;overflow: hidden;}
.sygctu{display: block;}
.sygctu img{display: block;width: 100%;max-width: 620px;max-height: 450px;transition: 0.5s;}
.sygctu:hover img{transform: scale(1.1);}
.gccover{position: absolute;left: 0;bottom: 0;width: 100%;background: url(../images/factorycover.png);text-align: center;line-height: 32px;padding-top: 28px;padding-bottom: 28px;}
.gccover a{display: block;margin: 0 15px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #fff;font-size:18px;font-size:1.8rem;font-weight: bold;}
.gccover2{position: absolute;left: 0;bottom: 100%;width: 100%;height: 100%;background: url(../images/factorycover.png);transition: 0.5s;}
.gcp1{display: block;max-width: 430px;margin: 0 auto;font-size:18px;font-size:1.8rem;font-weight: bold;line-height: 34px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;color: #fff;margin-top: 25%;}
.gcp2{display: block;max-width: 430px;margin: 0 auto;color: #fff;line-height: 24px;height: 72px;overflow: hidden;margin-top: 7px;}

.sygcbox:hover .gccover{display: none;}
.sygcbox:hover .gccover2{bottom: 0}

@media screen and (max-width:1419px) {
    .gcp1{max-width: 340px;}
    .gcp2{max-width: 340px;}
    .gccover a{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:1219px) {
    .gcp1{max-width: 300px;margin-top: 20%;}
    .gcp2{max-width: 300px;}
}
@media screen and (max-width:999px) {
    .sybt{font-size: 30px;font-size: 3rem;}
    .sygc .sybt{margin-bottom: 35px;}
    .sygcbox{width: 32.333%;margin: 0 0.5%;}
    .gccover{display: none;}
    .gccover2{position: static;width: auto;padding: 0 10px;padding-top: 10px;padding-bottom: 10px;}
    .gcp1{margin-top: 0;max-width: 100%;font-size:16px;font-size:1.6rem;}
    .gcp2{max-width: 100%;}
}
@media screen and (max-width:767px) {
    .sygc{padding-top: 40px;}
}
@media screen and (max-width:650px) {
    .sygcbox{width: 49%;margin-bottom: 10px;}
    .sygc{background: #fff;padding-bottom: 20px;}
}
@media screen and (max-width:400px) {
    .sygcbox{width: 99%;}
    .sybt{font-size:24px;font-size:2.4rem;}
    .sygc .sybt{margin-bottom: 20px;}
}

/*************  syn  ************/
.syn{padding-top: 76px;padding-bottom: 115px;background: url(../images/sybg2.jpg) repeat center top;}
.syn .sybt{margin-bottom: 34px;}

.syn .img-scroll {position: relative;padding-bottom: 50px;}
.syn .img-scroll .prev,.syn .img-scroll .next {position: absolute;display: block;bottom:0;color: #fff;text-align: center;}
.syn .img-scroll .prev {left: 50%;margin-left: -34px;cursor: pointer;}
.syn .img-scroll .next {right: 50%;margin-right:-34px;cursor: pointer;}
.syn .img-scroll .prev i, .syn .img-scroll .next i{color: #999999;font-size:24px;font-size:2.4rem;}
.syn .img-scroll .prev i:hover, .syn .img-scroll .next i:hover{color: #1f5d92;}

.syn .img-list {width:100%;overflow: hidden;margin: 0 auto;}
.syn .img-list ul {width: 999999px;}
.syn .img-list li {float: left;display: inline;width: 675px;position: relative;margin-right: 48px;}

.ntu{display: inline-block;overflow: hidden;}
.ntu img{width: 100%;max-width: 457px;display: block;max-height: 343px;transition: 0.5s;}
.ntu:hover img{transform: scale(1.1);}
.nw{position: absolute;right: 0;z-index: 999;width: 274px;height: 224px;background: url(../images/syncover.png);top: 57px;}
.np1{line-height: 29px;font-size: 18px;font-size: 1.8rem;padding: 0 20px;margin-top: 19px;margin-bottom: 6px;font-weight: bold;font-style: italic;}
.np1 a{color: #fff;display: block;height: 58px;overflow: hidden;}
.np1:hover a{color: #c11b1f;}
.np2{line-height: 24px;padding-left:20px;padding-right: 10px;margin-bottom: 6px;}
.np2 a{color: #fff;display: block;height: 48px;overflow: hidden;}
.np2 a:hover{color: #c11b1f;}
.nsj{margin-left: 18px;padding-left: 20px;background: url("../images/sybiao.png") no-repeat left center;color: #dbdada;}
.vm4{display: block;margin-left: 20px;width: 116px;line-height: 30px;text-align: center;border: 1px solid #fff;color: #fff;margin-top: 33px;text-transform: uppercase;}
.vm4:hover{color: #c11b1f;border: 1px solid #c11b1f;}

@media screen and (max-width:1419px) {
    .syn .img-list li{width: 575px;}
}
@media screen and (max-width:1219px) {
    .syn .img-list li{width: 472px;margin-right: 35px;}
    .ntu{width: 360px;}
    .nw{top: 25px;width: 240px;}
}
@media screen and (max-width:999px) {
    .syn .img-list ul{width: 10000%;}
    .syn .img-list ul li{width: 0.48%;margin: 0 0.01%;}
    .nw{position: static;width: 100%;padding-top: 10px;height: auto;padding-bottom: 20px;}
    .np1{margin-top: 0;}
    .vm4{margin-top: 12px;}
    .ntu{width: auto;margin: 0 auto;}
    .syn{padding-top: 50px;padding-bottom: 60px;}
}
@media screen and (max-width:767px) {
    .np1{font-size:16px;font-size:1.6rem;}
}
@media screen and (max-width:450px) {
    .syn .img-list ul li{width: 0.98%;}
    .syn{padding-top: 30px;padding-bottom: 40px;}
    .syn .sybt{margin-bottom: 20px;}
}

/*************  bottom  ************/
.bottom{background: url(../images/sybg3.jpg) repeat center top;padding-top: 60px;padding-bottom: 26px;}
.bottom p,.bottom li,.bottom a,.bottom span{color: #bbbbbb;}
.bottom a:hover{color: #fff;}
.div>a,.div>span{display: block;font-size:24px;font-size:2.4rem;font-family: "HelveticaLTStd-BlkCondObl";color: #fff;margin-bottom: 16px;}

.div1{margin-right: 356px;}
.div1 li{line-height: 30px;text-transform: uppercase;}
.div2{width: 220px;}
.div2 li{line-height: 30px;}

.div3{width: 402px;}
.div3 form{padding-top: 26px;}
.div3 .xing{margin-bottom: 13px;}
.div3 .xing input{height: 31px;line-height: 31px;color: #333333;padding-left: 12px;font-size: 12px;font-size: 1.2rem;}
.div3 .intbox1 input{width: 182px;}
.div3 .intbox2 input{width: 390px;}
.div3 .intbox3 textarea{width: 390px;padding-left: 12px;line-height: 26px;padding-top: 4px;height: 74px;font-size: 12px;font-size: 1.2rem;}
.div3 .intbox4 input.syint1{width: 102px;float: left;}
.div3 .intbox4 input.syint2{padding: 0;width: 130px;height: 32px;line-height: 32px;text-align: center;background: #333333;color: #fff;font-size: 12px;font-size: 1.2rem;float: right;cursor: pointer;}
.div3 .intbox4 img{margin-left: 13px;cursor: pointer;}

.dibu{padding-top: 29px;}

.bsjj a{margin-left: 4px;transition: 0.5s;}
.bsjj a:hover{color: #1f5d92;}
.bsjj a i{font-size: 30px;font-size: 3rem;color: #fff;}
.copyright,.support{line-height: 24px;}
.cnzz{padding-left: 10px;}

.link{line-height: 30px;min-height: 30px;color: #ffffff;font-family: "HelveticaLTStd-BlkCondObl";}
.link a{padding: 0 20px;}
.link a,.link span{display: inline-block;color: #ffffff;}
.link a:hover{color: #1f5d92;}

@media screen and (max-width:1419px) {
    .div1{margin-right: 250px;}
}
@media screen and (max-width:1219px) {
    .div1{margin-right: 115px;}
    .copyright{float: none;}
    .bsjj{float: none;padding-top: 15px;padding-bottom: 10px;}
}
@media screen and (max-width:999px) {
    .div1,.div2{width: 50%;margin-right: 0;}
    .div3{float: left;margin-top: 30px;}
    .div > a, .div > span{font-size:20px;font-size:2rem;}
    .bottom{padding-top: 30px;}
}
@media screen and (max-width:767px) {
    .div{display: none;}
    .bottom{padding-top: 20px;padding-bottom: 50px;}
    .dibu{padding-top: 0;}
    .link{display: none;}
}

/************  bread  *************/
.mianbao{ font-size:16px;font-size:1.6rem; line-height:24px; color:#333333; padding-top:8px;padding-bottom: 12px;border-bottom: 1px solid #cdcdcd;}
.mianbao a{ color:#333333;}
.mianbao a:hover{ color:#1f5d92;}
.co00{ color:#1f5d92 !important}

.fymain{padding-top: 35px;padding-bottom: 100px;}

/************  about  ***********/
.ap1 img{display: block;width: 100%;max-width: 1200px;}
.ap2{font-family: "HelveticaLTStd-BlkCond";font-size:20px;font-size:2rem;color: #1f5d92;line-height: 30px;margin-top: 23px;margin-bottom: 13px;}
.ap3{font-size:16px;font-size:1.6rem;line-height: 30px;margin-bottom: 30px;}
.ap4 img{display: block;width: 100%;max-width: 1200px;}

.coxinn{padding-top: 30px;}
.coxinn .xing{ background:url(../images/xing.png) no-repeat left 18px; padding-left:24px;}
.coxinn .tex.xing{ background:url(../images/xing.png) no-repeat left 40px; padding-left:24px;}
.coxinn .int1{ padding-left:24px; float:left; margin-top:14px;}
.coxinn .int2{ padding-left:24px; float:right; margin-top:14px;}
.coxinn .int1 input,.coxinn .int2 input{ width:548px; height:41px;line-height: 41px;padding-left:14px;border: 1px solid #d2d2d2;color: #999999;font-size:18px;font-size:1.8rem;}
.coxinn textarea{ width: 1160px; height:167px; padding-left:14px;line-height:42px;border: 1px solid #d2d2d2;margin-top: 17px;color: #999999;font-size:18px;font-size:1.8rem;}
.coxinn .int5{ margin-top:24px;}
.coxinn .int3{ width:463px; height:41px; padding-left:14px; font-size:18px; font-size:1.8rem; line-height:41px; text-transform:uppercase; float:left;border: 1px solid #d2d2d2;color: #a6a6a6;}
.coyanzheng{ width:149px; height:43px; float:left; margin-left:71px; cursor:pointer;}
.coxinn .int4{ width:412px; height:43px; float:right; background:#1f5d92; font-size:20px;font-size:2rem; color:#fff; cursor:pointer; transition:0.5s;-moz-transition:0.5s;-o-transition:0.5s;-webkit-transition:0.5s;text-transform: uppercase;}
.coxinn .int4:hover{ background:#174e7e;}

@media screen and (max-width:1219px) {
    .coxinn .int1 input, .coxinn .int2 input{width: 430px;}
    .coxinn textarea{width: 940px;}
    .coxinn .int3{width: 360px;}
    .coyanzheng{margin-left: 20px;}
    .coxinn .int4{width: 380px;}
}
@media screen and (max-width:999px) {
    .fymain{padding-bottom: 40px;}
    .coxinn .int1,.coxinn .int2{width: 45%;}
    .coxinn .int1 input, .coxinn .int2 input{width: calc(100% - 14px);}
    .coxinn textarea{width: calc(100% - 14px);}
    .coxinn .int3{width: calc(100% - 14px);margin-bottom: 14px;}
    .coyanzheng{margin-left: 0;}
}
@media screen and (max-width:650px) {
    .coxinn .int1,.coxinn .int2{padding-left: 15px;}
    .coxinn .tex.xing{padding-left: 15px;}
    .coxinn .xing{padding-left: 15px;}
    .coxinn .int1, .coxinn .int2{float: none;width: auto;}
    .coxinn .int4{width: 100%;margin-top: 14px;}
    .coxinn .int1 input, .coxinn .int2 input,.coxinn textarea,.coxinn .int3{font-size:14px;font-size:1.4rem;}
}
@media screen and (max-width:500px) {
    .ap3{font-size:14px;font-size:1.4rem;margin-bottom: 15px;}
}

/***************  news1  ***************/
.fynbox { margin-bottom: 29px; }

.swp { margin-right: 59px; }

.swp img { display: block; width: 100%; max-width: 437px; max-height: 328px; }

.fynnr { padding-top: 37px; }

.fynp1 { display: block;font-size:18px;font-size:1.8rem;font-weight: bold;font-style: italic; line-height: 30px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }

.fyntime { font-size:16px;font-size:1.6rem; color: #999999; display: block; margin-top: 17px; margin-bottom: 6px; }

.fynp2 { display: block; font-size: 16px; font-size: 1.6rem; color: #333333; line-height: 30px; height: 90px; overflow: hidden; }

.fynmore { display: inline-block; width: 116px; height: 30px; line-height: 30px;text-align: center; border: 1px solid #333;color: #333333; margin-top: 38px; text-transform: uppercase;}

.fynmore:hover { background: #1f5d92; color: #fff; border: 1px solid #1f5d92;}

.fynp1:hover{color: #1f5d92;}

.fynp2:hover{color: #1f5d92;}

.ms_ye { position: relative; margin-top: 53px; text-align: center; }

.ms_ye a { display: inline-block; width: 25px; line-height: 25px; border-radius: 12px;text-align: center; margin-left: 4px; margin-right: 4px; font-size: 12px; font-size: 1.2rem; background: #f5f5f5; color: #333333;font-family: Verdana, Geneva, Tahoma, sans-serif;}

.ms_sx {background: none!important;margin: 0 8px!important;}

.ms_sx i{color: #1f5d92;vertical-align: middle;font-size:20px;font-size:2rem;}

.ms_sx:hover { color: #fff !important; }

.ms_ye .ms_current_ye { color: #fff;background: #1f5d92; }

.ms_ye a:hover { color: #fff !important;background: #1f5d92; }

@media screen and (max-width: 999px) { .swp { width: 320px; margin-right: 28px;}
  .fynnr { padding-top: 5px; }
  .fynmore { margin-top: 15px; }
  .ms_ye { margin-top: 40px; }
  .news1 .ms_ye { text-align: center; }
  .ms_ye a { margin-bottom: 10px; }
}
@media screen and (max-width: 767px) { .swp { float: none; text-align: center; width: auto; margin-right: 0; }
  .swp img { display: inline; }
  .fynnr { padding-top: 20px; text-align: center; }
  .fynbox { margin-bottom: 30px; } }

@media screen and (max-width: 450px) { 
    .fynp1 { font-size:16px;font-size:1.6rem; }
    .fynp2{font-size:14px;font-size:1.4rem;} 
}

/***********   news2  ************/
.xwxbt { font-size:20px;font-size:2rem; color: #333333; line-height: 30px;font-weight: bold;font-style: italic;text-align: center;}

.xwsjsjj { line-height: 26px; padding-top: 5px; text-align: center;}

.xwsjsjj span { display: block; color: #999999;font-size: 16px; font-size: 1.6rem; }

.nshare { display:block; margin-top: 8px;}

.nshare1 { color: #333333; display: inline-block; position: relative; padding-right: 5px; font-size: 16px; font-size: 1.6rem; }

.nshare2 { display: inline-block; vertical-align: middle; }

.nex { color: #333333; line-height: 30px; padding-top: 9px; padding-bottom: 52px; border-bottom: 1px solid #d2d2d2; margin-bottom: 42px; font-size: 16px; font-size: 1.6rem; }

.nex p, .nex li, .nex div, .nex span, .nex b { color: #333333; }

.nex a { color: #1f5d92; }

.xwsx { color: #333333; line-height: 26px; margin-bottom: 13px; }

.xwsx span { color: #888888; display: inline-block; margin-right: 10px; text-transform: uppercase; }

.xwsx span i { color: #1f5d92; font-size: 26px; font-size: 2.6rem; vertical-align: middle; margin-right: 10px; }

.xwsx a { color: #333; }

.xwsx a:hover { color: #1f5d92; }

@media screen and (max-width: 767px) { .xwxbt { font-size:18px;font-size:1.8rem; }
  .nex { padding-bottom: 40px; font-size:14px;font-size:1.4rem;} }


/**********   product1   **********/
.pyou{width: 860px;}
.pzuo{width: 300px;}

.subNavBox .subNav{cursor:pointer; line-height:28px; padding:6px; padding-left:14px; padding-right:35px; background:#f5f5f5 url(../images/prltjia.png) no-repeat right center; margin-bottom:4px;}
.subNavBox .subNav a{ font-size:16px; font-size:1.6rem; color:#333;}
.subNavBox .subNav:hover{background:#1f5d92 url(../images/prltjiah.png) no-repeat right center;}
.subNavBox .subNav:hover a{color:#fff;}
.subNavBox .currentDd a{color:#fff}
.subNavBox .currentDt{background:#1f5d92 url(../images/prltjiah.png) no-repeat right center;}
.subNavBox .navContent{display: none; background:#fdfdfd; padding-top:6px;padding-bottom:13px; padding-left:15px;margin-bottom: 8px;}
.subNavBox .navContent>li>a{display:block; font-size:14px; font-size:1.4rem; line-height:28px;color:#333333; padding-left:24px; background:url(../images/prltjian.jpg) no-repeat left 13px;}
.subNavBox .navContent>li>a:hover{color:#1f5d92;}
.subNavBox .navContent>li p{padding-left: 35px;line-height: 24px;}
.subNavBox .navContent>li p a{display: block;color: #999999;}
.subNavBox .navContent>li p a:hover{color:#1f5d92;}
.subNavBox .navContent>li div{padding-bottom: 12px;}

.title1 p{display: none;position: absolute;right: 10px;bottom: 10px;}
.title1 p span{display: block;width: 30px;height: 5px;margin-bottom: 3px;background: #fff;}
.title1{text-align: left;padding-left: 15px;font-size:24px;font-size:2.4rem;line-height: 50px;position: relative;background: #f5f5f5;font-weight: bold;font-style: italic;display: block;margin-bottom: 10px;text-transform: uppercase;}
.title1 a{color: #1f5d92;}
.titlems{margin-top: 50px;}

.taglist li{float: left;margin-right: 20px;font-size:16px;font-size:1.6rem;line-height: 24px;margin-bottom: 10px;}

.pbox{width: 275px;float: left;margin-left: 17px;text-align: center;margin-bottom: 20px;}
.ptu{display: block;border: 1px solid #eeeeee;}
.ptu img{display: block;width: 100%;max-width: 420px;max-height: 420px;}
.pp1{display: block;font-size:16px;font-size:1.6rem;line-height: 30px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;margin-top: 15px;font-weight: bold;}

.pp2{margin-top: 10px;text-align: center;position: relative;display: inline-block;}
.pp2 a{display: inline-block;width: 98px;height: 28px;line-height: 28px;text-align: center;border-radius: 14px;color: #333333;background: #f5f5f5;}
.pp2 input{cursor: pointer;position: absolute;left: -20px;top: 7px;}
.pp1:hover{color: #1f5d92;}
.pp2:hover{color: #1f5d92;}
.pp2 a:hover{color: #fff;background: #1f5d92;}
.pcon{position: relative;overflow: hidden;}
.pcover{position: absolute;left: 0;top: -100%;width: 100%;height: 100%;background: url(../images/lvbg.png);}
.pcover img{width: 48px;height: 48px;position: absolute;left: 50%;margin-left: -24px;top: 50%;margin-top: -24px;}
.pbox:hover .pcover{top: 0;}

.xiangshang{position: absolute;right: 0;bottom: 0;padding-right: 23px;background: url(../images/xs.jpg) no-repeat right 6px;}
.xiangshang input{width: 148px;height: 30px;line-height: 30px;text-align: center;color: #ffffff;border:1px solid #1f5d92;cursor: pointer;background: #1f5d92;font-size: 12px;font-size: 1.2rem;}
.xiangshang input:hover{background: #19507f;}

.product1 .ms_ye{text-align: left;}

@media screen and (max-width:1219px) {
    .pyou{width: 660px;}
    .pbox{width: 210px;margin-left: 15px;}
}
@media screen and (max-width:999px) {
    .pyou{width: 100%;}
    .pzuo{width: 100%;margin-top: 50px;}
    .pbox{width: 23%;margin: 0 1%;margin-bottom: 30px;}
    .pp1{font-size:14px;font-size:1.4rem;}
    .xiangshang{position: static;display: inline-block;}
    .pcover{display: none;}
    .product1 .ms_ye{text-align: center;}
}
@media screen and (max-width:767px) {
    .pp1{margin-top: 10px;}
    .pbox{width: 31.333%;}
}

@media screen and (max-width:550px) {
    .pbox{width: 48%;}
    .ms_sx{margin: 0 6px!important;}
}

/************  product2  *************/
/*-------------2-2-products start----------------------------------------------------------*/
.product2 .prob-pic { width: 422px; float: left; }

.product2 .prob-pic-big { position: relative; }

.product2 .prob-pic-big-img { display: block; line-height: 0; font-size: 0; border: 1px solid #eeeeee;}

.product2 .prob-pic-big-img .zoomPad { float: none; z-index: 8; }

.product2 .prob-pic-big-img .zoomPad > img { max-width: 100%; }

.product2 .zoomWrapperImage img { max-width: 1200px; }

.product2 .prob-pic-small { position: relative; margin-top: 19px; padding: 0 28px; }

.product2 .prob-pic-btn { position: absolute; top: 50%; margin-top: -10px;display: block;text-align: center;background-repeat: no-repeat; background-position: center; background-size: 100% 100%; }

.product2 .prob-pic-btn i { color: #cdcdcd; font-size: 20px; font-size: 2rem; }

.product2 .prob-pic-btn i:hover{color: #333333;}

.product2 .prob-pic-btn.prev { left: 0; }

.product2 .prob-pic-btn.next { right: 0; }

.product2 .prob-pic-list { overflow: hidden; }

.product2 .prob-pic-list ul { width: 1000%; overflow: hidden; }

.product2 .prob-pic-list ul li { position: relative; float: left; margin-right: 0.23%; width: 3.18%; }

.product2 .prob-pic-item .item-pic { border: 1px solid #eeeeee; overflow: inherit; display: block; }

.product2 .prob-pic-item .item-pic img { display: block; }

.product2 .prob-pic-item .item-pic.zoomThumbActive { border: solid 1px #1f5d92; }

.product2 .prob-pic-item .item-pic img { -webkit-transition: none; -o-transition: none; -moz-transition: none; transition: none; max-width: 100%; display: block; }

.share-title { display: inline-block; font-size: 14px; font-size: 1.4rem; color: #333333; position: relative; top: -8px; margin-right: 5px; }

.share-pic { display: inline-block; }

@media screen and (max-width: 1219px) { .zoomPad { cursor: default !important; }
  .zoomPup { display: none !important; }
  .zoomWindow { display: none !important; }
  .zoomPreload { display: none !important; } }

@media screen and (max-width: 767px) { .product2 .prob-pic { width: 100%; }
  .product2 .prob-pic-big { display: none; }
  .product2 .prob-pic-small { margin-top: 0; }
  .product2 .prob-pic-list ul li { margin-right: .2%; width: 3.2%; }
  .product2 .prob-pic-item .item-pic:before { display: none !important; }
  .product2 .prob-pic-item .item-pic.zoomThumbActive { border: 1px solid #eee; } }

@media screen and (max-width: 550px) { .product2 .prob-pic-list ul li { margin-right: .2%; width: 4.9%; } }

@media screen and (max-width: 480px) { .product2 .prob-pic-list ul li { margin-right: 0%; width: 10%; } }

/* pro-info */
.pro-info { width: 400px; }

.pro-info .pro-title { font-size:20px;font-size:2rem;font-weight: bold;line-height: 30px; font-style: normal;margin-bottom: 25px; margin-top: 51px; }

.pro-info .pro-desc { font-size: 16px; font-size: 1.6rem; line-height: 26px; color: #282828; min-height: 97px; margin: 0 0 16px; }

.pro-btn { display: inline-block; width: 126px; height: 35px; line-height: 35px; border-radius: 18px; font-size:16px;font-size:1.6rem; text-align: center; background-color: #fff; color: #1f5d92; border: 1px solid #1f5d92;}

.pro-btn:hover { background-color: #1f5d92; color: #fff;}

.pro-btn:first-child { margin-right: 6px; }

.pro-btns { margin-top: 26px;}
.pdf{margin-top: 26px;}

@media screen and (max-width: 1219px) { .pro-info .pro-desc { height: auto; min-height: auto; }
  .pro-btn:first-child { margin-right: 25px; } }

@media screen and (max-width: 767px) { .pro-info { margin: 30px auto 0; }
  .pro-info .pro-title { font-size: 20px; font-size: 2rem; line-height: 30px; }
  .pro-btns { text-align: center; }
  .pro-btn:first-child { margin-right: 10px; } }

@media screen and (max-width: 550px) { .pro-btn { width: 45%; margin: 0 2%; }
  .pro-btn:first-child { margin-right: 0; } }

.pro2-detail { margin-top: 61px; }

@media screen and (max-width: 767px) { .pro2-detail { margin-top: 30px; } }

.pro2-detail .item-desc { padding: 28px 0 30px; font-size: 14px; font-size: 1.4rem; line-height: 30px; }

.pro2-detail .item-desc, .pro2-detail .item-desc a, .pro2-detail .item-desc p, .pro2-detail .item-desc li, .pro2-detail .item-desc span, .pro2-detail .item-desc strong, .pro2-detail .item-desc b, .pro2-detail .item-desc i, .pro2-detail .item-desc em { color: #333; }

.pro2-detail .item-desc img, .pro2-detail .item-desc iframe { max-width: 100%; }

@media screen and (max-width: 767px) { .pro2-detail .item-desc { padding: 15px 0; line-height: 24px; } }

.pro2-title { font-weight: bold; font-size: 22px; font-size: 2.2rem; text-transform: uppercase; border-bottom: solid 1px #ccc; line-height: 34px; padding: 5px 0; }

.pro2-title, .pro2-title a { color: #253E86; }

@media screen and (max-width: 767px) { .pro2-title { font-size: 18px; font-size: 1.8rem; } }

/*-------------2-2-products end------------------------------------------------------------*/
@font-face { font-family: 'HelveticaLTStdLight'; src: url("../fonts/HelveticaLTStdLight.eot"); src: url("../fonts/HelveticaLTStdLight.eot") format("embedded-opentype"), url("../fonts/HelveticaLTStdLight.woff2") format("woff2"), url("../fonts/HelveticaLTStdLight.woff") format("woff"), url("../fonts/HelveticaLTStdLight.ttf") format("truetype"), url("../fonts/HelveticaLTStdLight.svg#HelveticaLTStdLight") format("svg"); }

.cpxx { font-size: 16px; font-size: 1.6rem; line-height: 30px;margin-bottom: 110px;}

.cpxx a { color: #1f5d92; }

.cpxx img, .cpxx iframe { max-width: 100%; }

.des { font-size: 20px; font-size: 2.0rem; font-weight: bold; color: #333333; padding-bottom: 15px;background: url(../images/desbg.jpg) repeat-x center bottom;margin-bottom: 16px; margin-top: 37px; text-transform: uppercase;}

.des span { display: inline-block; background: #fff; padding-right: 6px; }

.des a:hover{color: #1f5d92;}

.chanpinx { line-height: 30px; font-size: 16px; font-size: 1.6rem; color: #333333; }

.chanpinx p, .chanpinx li, .chanpinx span, .chanpinx b { color: #333333; }

.chanpinx a { color: #1f5d92; }

.chanpinx img { max-width: 100%; }

.chanpinx iframe { max-width: 100%; }

.chanpinx table, .nex table, .cpxx table { width: 97%; border-collapse: collapse; }

.chanpinx tr td, .nex tr td, .cpxx tr td { line-height: 39px; padding: 0 2px; }

.table { margin-top: 23px; }

.table table { width: 100%; border-collapse: collapse; }

.chanpinx .table table td { border: 1px solid #404040; }

.chanpinx .table table td:first-child { text-align: center; }

.zjplist .img-scroll { position: relative; padding-top: 4px;}

.lbbtn { position: absolute; top: -52px; text-align: center; background: #fff; right: 0; width: 69px; }

.zjplist .prev, .zjplist .next { display: block; color: #fff; text-align: center; width: 30px;height: 30px;line-height: 30px;text-align: center;border-radius: 50%;border: 1px solid #1f5d92;}

.zjplist .prev i, .zjplist .next i { font-size: 14px; font-size: 1.4rem; color: #1f5d92; }

.zjplist .prev:hover, .zjplist .next:hover{background: #1f5d92;}

.zjplist .prev:hover i, .zjplist .next:hover i { color: #fff; }

.zjplist .prev { cursor: pointer; float: left; }

.zjplist .next { cursor: pointer; float: right; }

.zjplist .img-list { position: relative; width: 100%; overflow: hidden; margin: 0 auto; padding-bottom: 10px; }

.zjplist .img-list ul { width: 10000%; }

.zjplist .img-list li { float: left; display: inline; width: 275px; margin-right: 17px; position: relative; position: relative; }

.zjptu2 { display: block; border: 1px solid #f0f0f0; }

.zjptu2 img { display: block; width: 100%; max-width: 420px; max-height: 420px; }

.zjpp12 { display: block; text-align: center; font-size: 16px; font-size: 1.6rem; font-weight: bold;overflow: hidden; white-space: nowrap; text-overflow: ellipsis; margin-top: 14px; }

.zjpp12:hover { color: #1f5d92; }

@media screen and (max-width: 1000px) { .table { width: 100%; overflow: scroll; }
  .table table { width: 885px; } 
}

@media screen and (max-width: 1219px) { .pro-info { width: 415px; }
  .pro-info .pro-title {margin-top: 30px;margin-bottom: 15px;} 
  .product2 .prob-pic{float: none;margin: 0 auto;}
  .pro-info{width: 100%;}
  .cpxx{margin-bottom: 50px;}
  .zjplist .img-list li{width: 208px;}
}

@media screen and (max-width: 999px) { .product2 .prob-pic { float: none; margin: 0 auto; }
  .pro-info { width: 100%; text-align: center; margin-top: 20px; }
  .pro-info .pro-title{margin-top: 5px;}
  .zjplist .img-list li{width: 0.23%;margin: 0 0.01%;}
  .zjpp12{font-size:14px;font-size:1.4rem;}
}

@media screen and (max-width: 767px) { .product2 .prob-pic-btn { background: none; top: 50%; margin-top: -10px; }
  .product2 .prob-pic-btn i { color: #333; }
  .des { font-size:18px;font-size:1.8rem;} 
  .chanpinx{font-size:14px;font-size:1.4rem;}
  .zjplist .img-list li{width: 0.31333%;}
  .cpxx{font-size:14px;font-size:1.4rem;}
}

@media screen and (max-width: 550px) { 
    .pro-info .pro-title { font-size:18px;font-size:1.8rem; } 
    .zjplist .img-list li{width: 0.48%;}
    .pro-btns{float: none;margin-bottom: 20px;}
    .pdf{float: none;}
}


/************  download  ************/
.downbox { position: relative; padding-bottom: 41px; background: url(../images/desbg.jpg) repeat-x center bottom; margin-bottom: 42px; }

.dtu { position: absolute; left: 0; top: 8px; }

.dnr { padding-left: 83px; padding-right: 260px; }

.da1 { display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; font-size: 18px; font-size: 1.8rem; line-height: 30px; }

.da2 { display: block; line-height: 30px; margin-top: 4px; height: 60px; overflow: hidden; font-size: 16px; font-size: 1.6rem; }

.dbtn { display: block; width: 140px; height: 40px; line-height: 40px; text-align: center; border: 1px solid #1f5d92; background: #fff; position: absolute; right:0px; bottom: 65px; color: #1f5d92;}

.dbtn i { font-size: 18px; font-size: 1.8rem; vertical-align: middle;}

.da1:hover { color: #1f5d92; }

.da2:hover { color: #1f5d92; }

.dbtn:hover { background: #1f5d92; color: #fff; }

.download .ms_ye { margin-top: 58px; }

@media screen and (max-width:1219px) {
    .dnr{padding-right: 180px;}
}
@media screen and (max-width: 767px) { .dnr { padding-right: 0; padding-left: 70px; }
  .downbox { padding-bottom: 75px; margin-bottom: 40px; }
  .dbtn { bottom: 15px; }
  .da1 { font-size: 16px; font-size: 1.6rem; }
  .da2 { font-size: 14px; font-size: 1.4rem; height: auto; max-height: 90px; } }

/*************  contact  *************/
.contact .coxinn{padding-top: 0;padding-bottom: 57px;}
.contact .coxinn .int1 input, .contact .coxinn .int2 input{font-size:16px;font-size:1.6rem;}
.contact .coxinn textarea{font-size:16px;font-size:1.6rem;}
.contact .coxinn .int3{font-size:16px;font-size:1.6rem;}
.contact .coxinn .int4{background: #353944;font-size:16px;font-size:1.6rem;}
.ditu img{display: block;width: 100%;max-width: 1200px;max-height: 415px;}

@media screen and (max-width:767px) {
    .contact .coxinn{padding-bottom: 40px;}
}
























@media screen and (max-width:1419px) {
    
}
@media screen and (max-width:1219px) {
    
}
@media screen and (max-width:999px) {
    
}
@media screen and (max-width:767px) {
    
}
/*
font-family: "HelveticaLTStd-BlkCond";
font-family: "HelveticaLTStd-BlkCondObl";
*/
/***********  xiala  ***********/
.yiji{position: absolute;z-index: 9999;width: 198px;background: #f5f5f5;top: -9999px;left: 50%;margin-left: -80px;}
.yiji li{position: relative;}
.yiji li a{display: block;color: #333333;line-height: 27px;padding-top: 10px;padding-bottom: 10px;padding-left: 35px;padding-right: 10px;}
.yiji li:hover>a{background:#1f5d92;color: #fff;}

.erji,.sanji{position: absolute;left: 100%;top: -999px;padding-left: 1px;}
.erji ul,.sanji ul{background: #f5f5f5;width: 196px;}

.navlist>li:hover .yiji{top: 100%;}
.yiji>li:hover .erji{top: 0px;}
.erji li:hover .sanji{top: 0px;}

@media screen and (min-width:1000px) and (max-width:1219px) {

}
@media screen and (max-width:999px) {
    .yiji{display: none;}
}


.ce { position: fixed; right: 0px; top: 30%; width: 69px; text-align: center;z-index: 9999999999; }

.ce li { height: 69px; line-height: 69px;cursor: pointer;margin-bottom: 2px; -webkit-transition: 0.5s; -o-transition: 0.5s; -moz-transition: 0.5s; transition: 0.5s; border-radius: 5px;background: #f2f2f2;}

.ce li a { display: block; height: 69px; }
.ce li i{font-size: 30px;font-size: 3rem;}

.ce1 i{color: #09a8fa; }

.ce2 i{ color: #3ac34c;}

.ce3 i{ color: #83c14b;}

.ce4 i{ color: #007cbc;}

.ce .ce3 .ewm { position: absolute; bottom: 0; left: 125px; max-width: 120px; width: 120px; height: 120px; }

.ce .ce3:hover .ewm { left: -125px; }


@media screen and (max-width:767px) {
    .ce{bottom: 0;top: auto;width: 100%;}
    .ce li{width: 25%;margin: 0;float: left;height: 40px;line-height: 40px;border-radius: 0;border-radius: 0;box-shadow: none;}
    .ce li i{font-size:24px;font-size:2.4rem;}
    .link{padding-bottom: 10px;}
    .ce .ce3 .ewm { position: absolute; bottom: 50px; left: 80%; margin-left: -60px; display: none; }
    .ce .ce3:hover .ewm { left: 80%; }
}

.hdtop{position: fixed;right: 20px;bottom: -100px;transition: 1s;cursor: pointer;z-index: 99999;}



@font-face {
    font-family: "HelveticaLTStd-BlkCond";
    src: url("../fonts/HelveticaLTStd-BlkCond.woff2") format("woff2"),
         url("../fonts/HelveticaLTStd-BlkCond.woff") format("woff"),
         url("../fonts/HelveticaLTStd-BlkCond.ttf") format("truetype"),
         url("../fonts/HelveticaLTStd-BlkCond.eot") format("embedded-opentype"),
         url("../fonts/HelveticaLTStd-BlkCond.svg") format("svg");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "HelveticaLTStd-BlkCondObl";
    src: url("../fonts/HelveticaLTStd-BlkCondObl.woff2") format("woff2"),
         url("../fonts/HelveticaLTStd-BlkCondObl.woff") format("woff"),
         url("../fonts/HelveticaLTStd-BlkCondObl.ttf") format("truetype"),
         url("../fonts/HelveticaLTStd-BlkCondObl.eot") format("embedded-opentype"),
         url("../fonts/HelveticaLTStd-BlkCondObl.svg") format("svg");
    font-weight: normal;
    font-style: normal;
  }

/*
font-family: "HelveticaLTStd-BlkCond";
font-family: "HelveticaLTStd-BlkCondObl";
*/


